import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import dets from "../../dets.js";
import Uris from "../components/uris/Uris";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="gStopTimes" mdxType="Seo" />
    <h1>{`GStopTime`}</h1>
    <p>{`Extension of the data from GTFS stoptimes.txt file. It contains everything from that file plus bus stop and route information.`}</p>
    <section>
      <h2 id={dets.stopTimes.schemaId}>GStopTime Schema</h2>
  <SchemaTable dets={dets.stopTimes} mdxType="SchemaTable" />
    </section>
    <section>
      <h2 id="gstoptimes-by-stopid">GET GStopTime by stopid</h2>
      <p>{dets.stopTimes.routes[0].desc}</p>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.stopTimes.routes[0].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/gstoptimes/bystopid/8460B5255401/20220212
`}</code></pre>
  <details>
        <summary>
  <h4>Response</h4>
        </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "date": "Sat, 12 Feb 2022 00:00:00 GMT",
    "stop_times": [
      {
        "trip_id": "3774175.y104g.10-404-e20-1.1546.O",
        "arrival_time": "06:29:45",
        "departure_time": "06:29:45",
        "stop_id": "8460B5255401",
        "stop_name": "Westside Centre, stop 525541",
        "stop_lon": -9.07760775113074,
        "stop_lat": 53.2761484354635,
        "stop_sequence": 20,
        "route_short_name": "404",
        "direction_id": "0",
        "trip_headsign": "Oranmore (Opp Oran Town Centre) - Westside (Shopping Centre)",
        "first_stop_id": "8460B5243701",
        "first_stop_name": "Eyre Square, stop 524371",
        "last_stop_id": "8460B5255401",
        "last_stop_name": "Westside Centre, stop 525541",
        "shape_dist_traveled": 6725.97,
        "service_id": "y104g",
        "route_id": "10-404-e20-1"
      },
      // ... more GStopTimes
      {
        "trip_id": "3714814.60.10-404-e19-1.1543.I",
        "arrival_time": "23:40:00",
        "departure_time": "23:40:00",
        "stop_id": "8460B5255401",
        "stop_name": "Westside Centre, stop 525541",
        "stop_lon": -9.07760775113074,
        "stop_lat": 53.2761484354635,
        "stop_sequence": 1,
        "route_short_name": "404",
        "direction_id": "1",
        "trip_headsign": "Westside (Shopping Centre)...",
        "first_stop_id": "8460B5255401",
        "first_stop_name": "Westside Centre, stop 525541",
        "last_stop_id": "8460B5226101",
        "last_stop_name": "Eyre Square, stop 522611",
        "shape_dist_traveled": 0,
        "service_id": "60",
        "route_id": "10-404-e19-1"
      }
    ]
  }
}
`}</code></pre>
  </details>
    </section>
    <section>
      <h2 id="gstoptimes-by-stopid-routeid">GET GStopTimes by stopid & routeid</h2>
      <p>{dets.stopTimes.routes[1].desc}</p>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.stopTimes.routes[1].params} mdxType="ParamsTable" />
      <p>{`A small number of bus stops have buses travelling in both directions on a route - use `}<inlineCode parentName="p">{`0`}</inlineCode>{` or `}<inlineCode parentName="p">{`1`}</inlineCode>{` to specify `}<inlineCode parentName="p">{`:directionid`}</inlineCode>{`.`}</p>
      <p><inlineCode parentName="p">{`api/gstoptimes/bystopidrouteid/8460B5255401/404/0/20220215`}</inlineCode></p>
      <p>{`Otherwise use 'directionid' to get each direction that applies.
`}<inlineCode parentName="p">{`api/gstoptimes/bystopidrouteid/8460B5255401/404/directionid/20220215`}</inlineCode></p>
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/gstoptimes/bystopidrouteid/8460B5255401/404/0/20220215
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
  </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "date": "Tue, 15 Feb 2022 00:00:00 GMT",
    "g_stop_times": [
      {
        "trip_id": "3741557.y104h.10-404-e20-1.1546.O",
        "arrival_time": "06:32:15",
        "departure_time": "06:32:15",
        "stop_id": "8460B5255401",
        "stop_name": "Westside Centre, stop 525541",
        "stop_lon": -9.07760775113074,
        "stop_lat": 53.2761484354635,
        "stop_sequence": 20,
        "route_short_name": "404",
        "direction_id": "0",
        "trip_headsign": "Oranmore (Opp Oran Town Centre) - Westside (Shopping Centre)",
        "first_stop_id": "8460B5243701",
        "first_stop_name": "Eyre Square, stop 524371",
        "last_stop_id": "8460B5255401",
        "last_stop_name": "Westside Centre, stop 525541",
        "shape_dist_traveled": 6725.97,
        "service_id": "y104h",
        "route_id": "10-404-e20-1"
      }
      // ...
      // All other gStopTimes
    ]
  }
}
`}</code></pre>
  </details>
    </section>
    <section className="minHeightSection">
  <h2 id="gstoptimes-by-tripid">Get GStopTimes by tripid</h2>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.stopTimes.routes[2].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/gstoptimes/bytripid/3714818.60.10-404-e19-1.1544.I
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
  </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
"results": [
    "stop_times": [
      {
        "trip_id":"3714818.60.10-404-e19-1.1544.I",
        "arrival_time":"06:40:45",
        "departure_time":"06:40:45",
        "stop_id":"8460B5255601",
        "stop_sequence":2,
        "shape_dist_traveled":398.11,
        "service_id":"60",
        "shape_id":"10-404-e19-1.1544.I",
        "trip_headsign":"Westside (Shopping Centre)...",
        "route_id":"10-404-e19-1",
        "direction_id":"1",
        "first_stop_name":"Westside Centre, stop 525541",
        "first_stop_id":"8460B5255401",
        "last_stop_id":"8470B556161",
        "last_stop_name":"Oranmore Centre, stop 556161",
        "stop_name":"Gaelcarrig Park, stop 525561",
        "stop_lon":-9.07773025560116,
        "stop_lat":53.2792300347869
      },
      // ...

        // All other gStopTimes on this trip
      ]
    ]

}
`}</code></pre>
  </details>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      